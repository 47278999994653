<template>
  <div class="header-bar">
    <div class="header_container flex flex-vc flex-jsb">
      <a href="" class="logo">
        <img src="@/assets/logo.png" alt="" />
      </a>
      <div class="header-right-side flex flex-vc">
        <ul class="header-menu flex flex-vc" ref="menus">
          <div class="active-bar"></div>
          <li
            v-for="item in navArr"
            :key="item.path"
            :class="[
              item.path === activePath ? 'nav-active' : '',
              item.children && item.children.length > 0 ? 'hasChildren' : '',
            ]"
            @click="handleClick(item)"
          >
            <el-dropdown
              v-if="item.children && item.children.length > 0"
              placement="bottom"
            >
              <div class="sub-menu flex flex-vc flex-jsb">
                {{ item.name }}<i class="el-icon-arrow-down el-icon--right"></i>
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="subitem in item.children"
                  :key="subitem.path"
                  :disabled="pathUrl == subitem.path"
                  @click.native="submenuClick(item, subitem)"
                  class="dropdown-item"
                  >{{ subitem.name }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <!-- 只有一级菜单 -->
            <span v-else>{{ item.name }}</span>
          </li>
        </ul>
      </div>
      <div class="flex flex-vc header_right_login">
        <div class="switch_lang">
          <span
            v-for="lang in langArr"
            :key="lang.zh"
            v-text="lang.name"
            :class="[
              'lang_item',
              lang.zh === $i18n.locale ? 'lang_active' : '',
            ]"
            @click="toggleLang(lang.zh)"
          ></span>
        </div>
        <div class="login">
          <template v-if="showUserHead">
            <el-popover
              title=""
              width="200"
              trigger="hover"
              @show="showUser"
              popper-class="loginPopover"
            >
              <img
                slot="reference"
                src="@/assets/inner-layout/headImg.png"
                alt=""
                class="headImg"
              />
              <div class="flex flex-v flex-vc">
                <span class="user_phone"
                  >{{ userInfo.nick_name }},{{ $t("innerLayout.hello") }}</span
                >
                <div class="user_code flex flex-vc">
                  <span
                    >{{ $t("innerLayout.userCodeText") }}：{{
                      userInfo.user_code
                    }}</span
                  >
                  <div class="user_type flex flex-vc flex-hc">
                    <span>{{
                      userInfo.type === 1
                        ? $t("innerLayout.mechanismUserType")
                        : $t("innerLayout.personUserType")
                    }}</span>
                  </div>
                </div>
                <el-button @click="handleLogout" class="logOut">{{
                  $t("innerLayout.exitText")
                }}</el-button>
              </div>
            </el-popover>
          </template>
          <el-button
            class="login_button"
            type="primary"
            @click="handleLogin"
            v-else
            >{{ $t("innerLayout.loginText") }}</el-button
          >
        </div>
      </div>
    </div>
    <login :visible="loginVisible" @onClose="handleClose" />
  </div>
</template>

<script>
import Login from "../login";
import { mapState, mapMutations } from "vuex";
import api from "@/api";
export default {
  components: {
    Login,
  },
  computed: {
    langType() {
      return this.$i18n.locale == "zh" ? "简体中文" : "繁體中文";
    },
    navArr() {
      return this.$t("innerLayout.header.nav") || [];
    },
    pathUrl() {
      return this.$route.path;
    },
    ...mapState("user", ["userInfo", "loginVisible"]),
    showUserHead() {
      return !!this.userInfo.token;
    },
  },
  data() {
    return {
      activeName: "second",
      activeIndex: "1",
      activeIndex2: "1",
      activePath: "",
      subItemPath: "",
      beforeRouteName: "",
      showDropdown: false,
      langArr: [
        {
          name: "简",
          zh: "zh",
        },
        {
          name: "繁",
          zh: "hk",
        },
      ],
      dialogVisible: false,
    };
  },

  watch: {
    // 每次地址改变 计算执行动画
    // activePath() {
    // this.resetTabActivePosition(this.$refs.menus);
    // },
    //监听当前地址
    $route() {
      // console.log(this.$route);
      // this.beforeRoutePath = val.path;
      this.initBar();
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      console.log(vm);
    });
  },
  mounted() {
    //初始化动画路径
    this.initBar();
  },
  methods: {
    async showUser() {
      const userInfo = await api.user.v2UserInfo();
      this.SET_USER_INFO({ ...this.userInfo, ...userInfo });
    },
    enterView() {
      this.showDropdown = true;
    },
    leaveView() {
      this.showDropdown = false;
    },
    leaveViewDrop() {
      // this.showDropdown = false;
    },
    // 初始化或者变动底部bar
    initBar() {
      const path = this.$route.path.split("/")[1];
      // 储存上次访问的tabbar页面
      const filterPath = this.navArr.filter((item) => item.path === "/" + path);
      if (filterPath && filterPath.length > 0) {
        this.activePath = filterPath[0].path;
        sessionStorage.setItem("activePath", this.activePath);
      } else {
        this.activePath = sessionStorage.getItem("activePath") || "/home";
      }
      // 计算
      this.resetTabActivePosition(this.$refs.menus);
    },
    // 底部bar跟随距离计算
    resetTabActivePosition($el) {
      this.$nextTick(() => {
        const activeEl = $el.querySelector("li.nav-active");
        // console.log(activeEl);
        if (!activeEl) return;
        // console.log(activeEl.clientWidth);
        const lineEl = $el.querySelector(".active-bar");
        const style = getComputedStyle(activeEl);
        // const pl = style.paddingLeft.match(/\d+/)[0] * 1;
        const pr = style.paddingRight.match(/\d+/)[0] * 1;
        // const w = style.width.match(/\d+/)[0] * 1;
        lineEl.style.transform =
          "translateX(" +
          (activeEl.offsetLeft +
            activeEl.offsetWidth / 2 -
            lineEl.clientWidth / 2 -
            pr / 2) +
          "px)";
        // lineEl.style.width = w - pl - pr + "px";
      });
    },
    // 语言切换
    toggleLang(lang) {
      if (lang == "zh") {
        sessionStorage.setItem("language", "zh");
        this.$i18n.locale = sessionStorage.getItem("language");
      } else if (lang == "hk") {
        sessionStorage.setItem("language", "hk");
        this.$i18n.locale = sessionStorage.getItem("language");
      }
    },
    //一级tab点击
    handleClick(item) {
      if (item.children && item.children.length > 0) return;
      this.subItemPath = "";
      this.$router.push(item.path);
    },
    //二级tab点击
    submenuClick(item, subItem) {
      this.subItemPath = subItem.path;
      this.$router.push(subItem.path);
      this.showDropdown = false;
    },
    ...mapMutations("user", ["SET_LOGIN_VISIBLE", "SET_USER_INFO"]),
    handleLogin() {
      // this.dialogVisible = true;
      this.SET_LOGIN_VISIBLE(true);
    },
    handleClose() {
      // this.dialogVisible = false;
      this.SET_LOGIN_VISIBLE(false);
    },
    handleLogout() {
      this.$store.dispatch("user/logout");
      location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.header-bar {
  background: var(--HeaderBgColor);
  width: 100%;
  padding: 0 32px;
  height: 60px;
  font-size: 16px;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  .header_container {
    height: 100%;
  }
  .logo {
    width: 180px;
    // display: inline-block;
    img {
      width: 100%;
    }
  }
  .header-right-side {
    height: 100%;
    .header-menu {
      height: 100%;
      color: var(--whiteColor);
      position: relative;
      .sub-menu {
        color: var(--whiteColor);
        font-size: 16px;
        position: relative;
        i {
          color: var(--iconGray);
          font-size: 18px;
          font-weight: bolder;
          position: absolute;
          right: -25px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .active-bar {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 24px;
        height: 2px;
        border-radius: 5px;
        background-color: var(--whiteColor);
        z-index: 1;
        transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        list-style: none;
      }
      li {
        margin: 0 20px;
        // padding-right: 20px;
        cursor: pointer;
        &.nav-active,
        &:hover {
          font-weight: 500;
        }
        &.hasChildren {
          padding-right: 25px;
          position: relative;
        }
        .dropdown-box {
          .drop-view {
            position: absolute;
            width: 100px;
            left: 50%;
            transform: translateX(-50%);
            top: 16px;
            z-index: 100;
            padding-top: 12px;
            .sanjiao {
              height: 5px;
              position: relative;
              &::after {
                content: "";
                width: 0;
                height: 0;
                border-left: 7px solid transparent;
                border-right: 7px solid transparent;
                border-bottom: 7px solid #fff;
                position: absolute;
                left: 30px;
                top: 0px;
                border-radius: 0 0 2px;
              }
            }
          }
          .dropdown {
            position: relative;
            margin-top: 2px;
            background: var(--whiteColor);
            // position: absolute;
            // width: 100px;
            // left: 50%;
            // transform: translateX(-50%);
            // top: 35px;
            border-radius: 2px;
            box-shadow: 0px 6px 12px 0px rgba(54, 95, 163, 0.16);

            .dropdown-item {
              height: 40px;
              padding: 0 10px;
              line-height: 40px;
              font-size: 14px;
              color: var(--blackColor);
              font-weight: 400;
              &:hover {
                color: var(--blue);
                background: rgba(71, 141, 255, 0.1);
              }
              &[disabled="disabled"] {
                color: var(--blue);
              }
              &:nth-child(1) {
                .item-value {
                  border-top: none;
                }
              }
              .item-value {
                display: inline-block;
                height: 100%;
                width: 100%;
                border-top: 1px solid #eef1f3;
              }
            }
          }
        }
      }
    }
  }
  .switch_lang {
    width: 72px;
    height: 28px;
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid var(--whiteColor);
    font-size: 12px;
    color: var(--whiteColor);
    cursor: pointer;
    span {
      display: inline-block;
      width: 50%;
      text-align: center;
      line-height: 26px;
      &.lang_active {
        background: var(--whiteColor);
        color: var(--blackColor);
      }
    }
  }
  .login {
    margin-left: 40px;
    .login_button {
      background-color: var(--blue);
      width: 48px;
      height: 28px;
      padding: 0;
      font-size: 12px;
    }
    .headImg {
      width: 40px;
    }
    .logOut {
      width: 100%;
    }
  }
}
.el-dropdown-menu__item.is-disabled {
  color: var(--blue);
}
@media (max-width: 1440px) {
  .header-bar {
    .header_container {
      width: 1140px;
      margin: 0 auto;
      -webkit-box-pack: start;
      -moz-justify-content: flex-start;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      .header-right-side {
        margin-left: auto;
      }
    }
    .switch_lang {
      margin-left: 30px;
    }
  }
}
</style>
