<template>
  <empty-dialog
    v-model="visible"
    append-to-body
    :show-close="false"
    width="600px"
    top="10vh"
  >
    <div class="agreement_content order_content">
      <div class="agreement_title dialog_title">
        <span>{{ title }}</span>
      </div>
      <div class="agreement_body order_body">
        <slot></slot>
      </div>
      <div class="agreement_footer flex flex-hc">
        <el-button type="text" @click="visible = !visible">
          <span>{{ confirmText }}</span>
        </el-button>
      </div>
    </div>
  </empty-dialog>
</template>
<script>
import EmptyDialog from "@/components/business/EmptyDialog.vue";

export default {
  components: {
    EmptyDialog,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    confirmText: {
      type: String,
      default: "确定",
    },
  },
  computed: {},
  data() {
    return {
      visible: this.value,
    };
  },
  watch: {
    value(val) {
      this.visible = val;
    },
    visible(val) {
      this.$emit("input", val);
    },
  },
  mounted() {},
  unmounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.agreement_content {
  background: var(--whiteColor);
  border-radius: 8px;
  padding: 0px 0px 0px;
  color: var(--blackColor);
  font-size: 14px;
  .agreement_title {
    margin-left: 24px;
    margin-right: 24px;
    padding: 16px 0;
    font-size: 16px;
    line-height: 22px;
    font-weight: bold;
    border-bottom: 1px solid #eef1f3;
  }
  .agreement_body {
    max-height: 480px;
    overflow-y: auto;
    padding: 0 24px;
    margin: 20px 0;
    ::v-deep * {
      font-family: "PingFang SC", "Myriad Pro", "Hiragino Sans GB",
        "Microsoft YaHei", Arial, sans-serif !important;
      line-height: 1.5;
    }
    &::-webkit-scrollbar {
      width: 4.5px;
    }
  }
  .agreement_footer {
    padding-top: 20px;
    border-top: 1px solid #eef1f3;
    padding-bottom: 12px;
    ::v-deep .el-button--text {
      padding: 0;
    }
  }
}
</style>
