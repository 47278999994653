<template>
  <el-dropdown @command="handleCommand">
    <div class="dropdown_box flex flex-jsb flex-vc" :style="{ width: width }">
      <span>{{ labelText }}</span
      ><img src="@/assets/inner-layout/dropdown.png" alt="" />
    </div>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item
        v-for="(item, idx) in columns"
        :key="item[valueKey] || idx"
        :command="item[valueKey] || idx"
        >{{ item[labelKey] }}</el-dropdown-item
      >
    </el-dropdown-menu>
  </el-dropdown>
</template>
<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: "",
    },
    columns: {
      type: Array,
      default: () => [],
    },
    labelKey: {
      type: [String, Number],
      default: "label",
    },
    valueKey: {
      type: [String, Number],
      default: "value",
    },
    width: {
      type: [String, Number],
      default: "",
    },
  },
  computed: {
    labelText() {
      const item = this.columns.filter(
          (item) => item[this.valueKey] === this.val
        )[0],
        text = item ? item[this.labelKey] : "";
      return text;
    },
  },
  data() {
    return {
      val: this.value,
    };
  },
  methods: {
    handleCommand(command) {
      this.val = command;
      this.$emit("input", command);
    },
  },
};
</script>
<style lang="scss" scoped>
.dropdown_box {
  width: 100%;
  img {
    width: 8px;
    transition: all 0.2s ease-out;
  }
  &:hover {
    img {
      transform: rotateX(180deg);
    }
  }
}
</style>
