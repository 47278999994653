<template>
  <el-dialog
    title=""
    :visible.sync="dialogVisible"
    width="515px"
    :show-close="false"
    append-to-body
    lock-scroll
  >
    <img src="../../assets/common/deal.png" alt="" class="dealImg" />
    <div slot="footer" class="dialog-footer flex flex-vc flex-v">
      <div class="line"></div>
      <div class="bot-button flex flex-jsb">
        <el-button round @click="closeWindow" class="closeWindow">{{
          $t("common.closeWindow")
        }}</el-button>
        <el-button type="primary" class="goLogin" round @click="goLogin">{{
          $t("common.goLogin")
        }}</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogVisible: this.value,
    };
  },
  computed: {},
  watch: {
    value(n, o) {
      if (n === o) return;
      this.dialogVisible = n;
    },
    dialogVisible(n, o) {
      if (n === o) return;
      this.$emit("input", n);
    },
  },
  methods: {
    goPage() {
      this.dialogVisible = false;
    },
    closeWindow() {
      this.dialogVisible = false;
    },
    goLogin() {
      this.goPage();
      setTimeout(() => {
        window.open(this.$t("business.securities.dealLinkUrl"));
      }, 0);
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-dialog {
  position: relative;
  padding-bottom: 16px;
  .el-dialog__header {
    height: 0;
    padding: 0;
  }
  .el-dialog__body {
    padding: 38px 19px 24px;
  }
  .el-dialog__footer {
    padding: 0;
  }
  .el-button.is-round {
    padding: 13px 32px;
    border-radius: 100px;
    font-size: 14px;
    font-weight: 400;
  }
}
.dealImg {
  width: 476px;
}
.line {
  width: 454px;
  height: 1px;
  background: var(--borderColor);
}
.bot-button {
  width: 100%;
  padding: 16px 117px 0;
}
.closeWindow {
  border: 1px solid var(--iconGray);
  background: var(--whiteColor);
  color: var(--blackColor);
}
.goLogin {
  background-color: var(--blue);
  border-color: transparent;
}
</style>
