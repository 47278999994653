<template>
  <div class="side-bar flex flex-v flex-vc">
    <!-- 侧边导航菜单 -->
    <div class="bar-item">
      <a
        href="javascript:;"
        class="flex flex-v flex-vc"
        @click="showDeal = !showDeal"
      >
        <img src="@/assets/home/slide-deal1.png" alt="" />
        <span>{{ $t("innerLayout.slideBar.deal") }}</span>
      </a>
    </div>
    <div class="border"></div>
    <!-- <div
      class="bar-item flex flex-v flex-vc"
      @click="$router.push('/service/help')"
    > -->
    <div class="bar-item flex flex-v flex-vc" @click="show = !show">
      <img src="@/assets/home/slide-open1.png" alt="" />
      <!-- <span>{{ $t("innerLayout.slideBar.open") }}</span> -->
      <span>{{ $t("business.securities.openOnline") }}</span>
    </div>
    <div class="border"></div>
    <div
      class="bar-item flex flex-v flex-vc"
      @click="$router.push('/download')"
    >
      <img src="@/assets/home/slide-down1.png" alt="" />
      <span>{{ $t("innerLayout.slideBar.down") }}</span>
    </div>
    <div class="border"></div>
    <div class="bar-item flex flex-v flex-vc" @click="$router.push('/contact')">
      <img src="@/assets/home/slide-phone1.png" alt="" />
      <span>{{ $t("innerLayout.slideBar.phone") }}</span>
    </div>
    <model-box v-model="show" />
    <model-deal v-model="showDeal" />
  </div>
</template>

<script>
import ModelBox from "@/components/business/ModelBox.vue";
import ModelDeal from "@/components/business/ModelDeal.vue";
export default {
  components: {
    ModelBox,
    ModelDeal,
  },
  data() {
    return {
      show: false,
      showDeal: false,
    };
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
.side-bar {
  width: 80px;
  position: fixed;
  bottom: 200px;
  right: 20px;
  background-color: var(--whiteColor);
  z-index: 1000;
  padding: 18px 0;
  border-radius: 2px;
  box-shadow: 0px 6px 12px 0px rgba(54, 95, 163, 0.16);
  .bar-item {
    color: var(--blackColor);
    font-size: 12px;
    a:hover {
      text-decoration: none;
    }
    img {
      width: 44px;
      // margin-bottom: 5px;
    }
    cursor: pointer;
  }
  .border {
    width: 60px;
    height: 1px;
    background: var(--slideBorderColor);
    border-radius: 1px;
    margin: 11px 0;
  }
}
// @media screen and (min-width: 1140px) and (max-width: 1440px) {
//   .side-bar {
//     right: 10px;
//   }
// }
// @media screen and (min-width: 1441px) {
//   .side-bar {
//     right: 120px;
//   }
// }
</style>
