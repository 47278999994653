import api from "@/api";
// import { Loading } from "element-ui";
export const imgValid = () => {
  // let loadingInstance = Loading.service({
  //   lock: true,
  // });
  return new Promise((resolve, reject) => {
    api.user
      .securityGet()
      .then((data) => {
        if (data) {
          window.initGeetest(
            {
              // 以下配置参数来自服务端 SDK
              gt: data.gt,
              challenge: data.challenge,
              offline: !data.success,
              new_captcha: data.new_captcha,
              product: "bind",
            },
            (captchaObj) => {
              captchaObj
                .onReady(() => {
                  captchaObj.verify();
                })
                .onSuccess(() => {
                  const result = captchaObj.getValidate();
                  api.user
                    .securityValid({
                      geetest_challenge: result.geetest_challenge,
                      geetest_validate: result.geetest_validate,
                      geetest_seccode: result.geetest_seccode,
                    })
                    .then(() => {
                      captchaObj.destroy();
                      resolve(result);
                    })
                    .catch((err) => {
                      reject(err);
                    });
                })
                .onError((err) => {
                  console.log(err);
                  reject("onError");
                  console.log("onError");
                });
            }
          );
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};
