var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header-bar"},[_c('div',{staticClass:"header_container flex flex-vc flex-jsb"},[_vm._m(0),_c('div',{staticClass:"header-right-side flex flex-vc"},[_c('ul',{ref:"menus",staticClass:"header-menu flex flex-vc"},[_c('div',{staticClass:"active-bar"}),_vm._l((_vm.navArr),function(item){return _c('li',{key:item.path,class:[
            item.path === _vm.activePath ? 'nav-active' : '',
            item.children && item.children.length > 0 ? 'hasChildren' : '',
          ],on:{"click":function($event){return _vm.handleClick(item)}}},[(item.children && item.children.length > 0)?_c('el-dropdown',{attrs:{"placement":"bottom"}},[_c('div',{staticClass:"sub-menu flex flex-vc flex-jsb"},[_vm._v(" "+_vm._s(item.name)),_c('i',{staticClass:"el-icon-arrow-down el-icon--right"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},_vm._l((item.children),function(subitem){return _c('el-dropdown-item',{key:subitem.path,staticClass:"dropdown-item",attrs:{"disabled":_vm.pathUrl == subitem.path},nativeOn:{"click":function($event){return _vm.submenuClick(item, subitem)}}},[_vm._v(_vm._s(subitem.name))])}),1)],1):_c('span',[_vm._v(_vm._s(item.name))])],1)})],2)]),_c('div',{staticClass:"flex flex-vc header_right_login"},[_c('div',{staticClass:"switch_lang"},_vm._l((_vm.langArr),function(lang){return _c('span',{key:lang.zh,class:[
            'lang_item',
            lang.zh === _vm.$i18n.locale ? 'lang_active' : '',
          ],domProps:{"textContent":_vm._s(lang.name)},on:{"click":function($event){return _vm.toggleLang(lang.zh)}}})}),0),_c('div',{staticClass:"login"},[(_vm.showUserHead)?[_c('el-popover',{attrs:{"title":"","width":"200","trigger":"hover","popper-class":"loginPopover"},on:{"show":_vm.showUser}},[_c('img',{staticClass:"headImg",attrs:{"slot":"reference","src":require("@/assets/inner-layout/headImg.png"),"alt":""},slot:"reference"}),_c('div',{staticClass:"flex flex-v flex-vc"},[_c('span',{staticClass:"user_phone"},[_vm._v(_vm._s(_vm.userInfo.nick_name)+","+_vm._s(_vm.$t("innerLayout.hello")))]),_c('div',{staticClass:"user_code flex flex-vc"},[_c('span',[_vm._v(_vm._s(_vm.$t("innerLayout.userCodeText"))+"："+_vm._s(_vm.userInfo.user_code))]),_c('div',{staticClass:"user_type flex flex-vc flex-hc"},[_c('span',[_vm._v(_vm._s(_vm.userInfo.type === 1 ? _vm.$t("innerLayout.mechanismUserType") : _vm.$t("innerLayout.personUserType")))])])]),_c('el-button',{staticClass:"logOut",on:{"click":_vm.handleLogout}},[_vm._v(_vm._s(_vm.$t("innerLayout.exitText")))])],1)])]:_c('el-button',{staticClass:"login_button",attrs:{"type":"primary"},on:{"click":_vm.handleLogin}},[_vm._v(_vm._s(_vm.$t("innerLayout.loginText")))])],2)])]),_c('login',{attrs:{"visible":_vm.loginVisible},on:{"onClose":_vm.handleClose}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"logo",attrs:{"href":""}},[_c('img',{attrs:{"src":require("@/assets/logo.png"),"alt":""}})])
}]

export { render, staticRenderFns }