<template>
  <div class="form_item flex flex-vc">
    <div class="flex flex-vc form_item_prefix" :style="{ width: labelWidth }">
      <slot name="prefix">
        <span class="label">{{ label }}</span>
      </slot>
    </div>

    <el-input
      :placeholder="placeholder"
      v-model="val"
      class="flex1 input_item"
      autocomplete="off"
      auto-complete="off"
      :maxLength="maxLength"
      :type="type"
      @input="onInput"
    />
    <img
      v-show="!!val"
      class="del_icon"
      src="@/assets/inner-layout/del_icon.png"
      alt=""
      @click="clear"
    />
    <slot name="suffix"></slot>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    placeholder: {
      type: String,
      default: "",
    },
    labelWidth: {
      type: [String, Number],
      default: "",
    },
    label: {
      type: [String, Number],
      default: "",
    },
    maxLength: {
      type: [String, Number],
      default: "",
    },
  },
  data() {
    return {
      val: this.value,
    };
  },
  watch: {
    value(val) {
      this.val = val;
    },
  },
  methods: {
    onInput(val) {
      this.$emit("input", val);
    },
    clear() {
      this.val = "";
      this.$emit("input", "");
    },
  },
};
</script>
<style lang="scss" scoped>
.form_item {
  width: 100%;
  font-size: 14px;
  color: var(--blackColor);
  ::v-deep .el-input__inner {
    height: 36px;
    line-height: 36px;
    font-size: 14px;
  }
}
.input_item {
  ::v-deep .el-input__inner {
    padding-left: 10px;
    padding-right: 5px;
    border: none;
  }
}
.del_icon {
  width: 20px;
  cursor: pointer;
}
</style>
