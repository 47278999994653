<template>
  <el-dialog
    title=""
    :visible.sync="visible"
    width="488px"
    :before-close="handleClose"
    custom-class="login_dialog"
    :show-close="false"
    append-to-body
  >
    <div class="login_page flex flex-v flex-vc">
      <img
        class="login_logo"
        src="@/assets/inner-layout/login_logo.png"
        alt=""
      />
      <div class="login_title">{{ $t("innerLayout.login.logoName") }}</div>
      <div class="longin_typeName">{{ $t("innerLayout.login.loginType") }}</div>
      <!-- 手机号 -->
      <form-item
        label-width="70px"
        :placeholder="$t('innerLayout.login.phonePlaceholder')"
        v-model="formInfo.phone"
        @input="phoneInput"
      >
        <dropdown
          width="46px"
          slot="prefix"
          :columns="area"
          v-model="formInfo.area"
        />
      </form-item>
      <!-- 客户号 -->
      <form-item
        :label="$t('innerLayout.login.userCodeLabel')"
        label-width="70px"
        :placeholder="$t('innerLayout.login.userCodePlaceholder')"
        v-model="formInfo.user_code"
        v-show="userDisplay"
      />
      <!-- 验证码 -->
      <form-item
        :label="$t('innerLayout.login.phonecodeLabel')"
        label-width="70px"
        :placeholder="$t('innerLayout.login.phonecodePlaceholder')"
        v-model="formInfo.phonecode"
        v-if="loginByMessage"
        maxLength="6"
      >
        <span
          slot="suffix"
          :class="{ getCode: true, getingCode: getingCode }"
          @click="getCode"
        >
          {{ getingCode ? `${codeNumer}S` : $t("innerLayout.login.getCode") }}
        </span>
      </form-item>
      <!-- 登录密码 -->
      <form-item
        :label="$t('innerLayout.login.passwordLabel')"
        :type="passwordType"
        label-width="70px"
        :placeholder="$t('innerLayout.login.passwordPlaceholder')"
        v-model="formInfo.pwd"
        v-if="!loginByMessage"
      >
        <img
          slot="suffix"
          class="eyes"
          :src="
            passwordType === 'password'
              ? require('@/assets/inner-layout/eyes.png')
              : require('@/assets/inner-layout/eyes_nor.png')
          "
          alt=""
          @click="changePasswordType"
        />
      </form-item>
      <div class="risk_list flex flex-vc">
        <img
          :src="
            readed
              ? require('@/assets/inner-layout/radio_read.png')
              : require('@/assets/inner-layout/radio_no.png')
          "
          alt=""
          @click="readed = !readed"
        />
        {{ $t("innerLayout.login.readLeft")
        }}<a
          href="javascript:;"
          @click="disclaimerVisible = !disclaimerVisible"
          >{{ $t("innerLayout.login.readItem1") }}</a
        >和<a href="javascript:;" @click="privacyVisible = !privacyVisible">{{
          $t("innerLayout.login.readItem2")
        }}</a>
      </div>
      <el-button
        type="primary"
        :disabled="!submitDisabled"
        class="submmit"
        @click="onSubmit"
        >{{ $t("innerLayout.loginText") }}</el-button
      >
      <div class="loginType" @click="loginTypeChange">
        {{
          loginByMessage
            ? $t("innerLayout.login.pwdTypeText")
            : $t("innerLayout.login.messageTypeText")
        }}
      </div>
      <agreement-dialog
        v-model="disclaimerVisible"
        :confirm-text="$t(`common.defineText`)"
        :title="$t(`innerLayout.login.readItem1`)"
      >
        <disclaimer />
      </agreement-dialog>
      <agreement-dialog
        v-model="privacyVisible"
        :confirm-text="$t(`common.defineText`)"
        :title="$t(`innerLayout.login.readItem2`)"
      >
        <!-- <privacy /> -->
        <div v-html="$t('about.privacy.content')"></div>
      </agreement-dialog>
    </div>
  </el-dialog>
</template>

<script>
import Dropdown from "@/components/business/Dropdown.vue";
import FormItem from "@/components/business/FormItem.vue";
import Disclaimer from "@/views/about/disclaimer";
// import Privacy from "@/views/about/privacy";
import AgreementDialog from "@/components/business/AgreementDialog.vue";
import api from "@/api";
import { imgValid } from "@/utils/imgValid";
import { mapState, mapMutations } from "vuex";
import service from "@/utils/service";
import service1 from "@/utils/service1";
let timer;
export default {
  components: {
    Dropdown,
    FormItem,
    // Privacy,
    Disclaimer,
    AgreementDialog,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState("user", ["device_id"]),
    getingCode() {
      return this.codeNumer < 60;
    },
    submitDisabled() {
      const testSecret =
        this.formInfo.phone &&
        this.formInfo.phone.length > 7 &&
        this.formInfo.pwd &&
        this.formInfo.pwd.length > 5 &&
        this.readed &&
        ((this.userDisplay && this.formInfo.user_code.length > 0) ||
          !this.userDisplay);
      const testMessage =
        this.formInfo.phone &&
        this.formInfo.phone.length > 7 &&
        this.formInfo.phonecode &&
        this.formInfo.phonecode.length === 6 &&
        this.readed &&
        ((this.userDisplay && this.formInfo.user_code.length > 0) ||
          !this.userDisplay);
      return testSecret || testMessage;
    },
  },
  watch: {
    visible(val) {
      if (val) {
        this.formInfo.pwd = "";
        this.formInfo.phonecode = "";
        this.readed = false;
      }
    },
  },
  data() {
    return {
      formInfo: {
        phone: "",
        area: "china",
        phonecode: "",
        platform: "web",
        user_code: "",
        pwd: "",
      },
      area: [
        { label: "+86", value: "china" },
        { label: "+852", value: "hk" },
      ],
      codeNumer: 60,
      readed: false,
      loginByMessage: true,
      userDisplay: false,
      passwordType: "password",
      disclaimerVisible: false,
      privacyVisible: false,
    };
  },
  methods: {
    navigateTo(path) {
      this.SET_LOGIN_VISIBLE(false);
      this.$router.push(path);
    },
    handleClose() {
      this.$emit("onClose");
    },
    loginTypeChange() {
      this.loginByMessage = !this.loginByMessage;
      this.formInfo.pwd = "";
      this.formInfo.phonecode = "";
    },
    changePasswordType() {
      if (this.passwordType === "password") this.passwordType = "text";
      else this.passwordType = "password";
    },
    // 获取验证码
    getCode() {
      if (this.formInfo.phone.length < 7) {
        return;
      }
      // 验证
      if (timer) return;
      imgValid().then((result) => {
        const { geetest_challenge } = result;
        this.geetest_challenge = geetest_challenge;
        api.user
          .phonecodeGet({
            type: 1,
            ...this.formInfo,
            scene: "code_login",
            action: "code_login",
            geetest_challenge,
            device_id: this.device_id,
            platform: "web",
          })
          .then((res) => {
            if (res) {
              timer = setInterval(() => {
                this.codeNumer--;
                if (this.codeNumer === 0) {
                  clearInterval(timer);
                  this.codeNumer = 60;
                }
              }, 1000);
            }
          });
      });
    },
    //检测是否需要客户号
    phoneInput(val) {
      if (val.length == 8 || val.length == 11) {
        api.user
          .userCodeDisplay({
            input_value: val,
            type: "phone",
          })
          .then((res) => {
            this.userDisplay = res.display === 1;
          });
      } else {
        this.userDisplay = false;
      }
    },
    ...mapMutations("user", [
      "SET_USER_INFO",
      "SET_USER_ACCOUNT_LIST",
      "SET_LOGIN_VISIBLE",
    ]),
    getOtherInfo(data) {
      this.SET_USER_INFO(data);
      const { user_id, token } = data;
      service.setHeader({ user_id, token });
      service1.setHeader({ user_id, token });
      api.user.v2UserInfo().then((res) => {
        this.SET_USER_INFO({ token, ...res });
      });
      api.user.userTradeAccountList().then((res) => {
        this.SET_USER_ACCOUNT_LIST(res);
      });
      this.handleClose();
    },
    onSubmit() {
      const info = JSON.parse(JSON.stringify(this.formInfo));
      if (this.loginByMessage) {
        delete info.pwd;
        api.user.webCodeLogin(info).then((res) => {
          this.getOtherInfo(res);
        });
      } else {
        delete info.phonecode;
        api.user.webPwdLogin(info).then((res) => {
          this.getOtherInfo(res);
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login_dialog {
  ::v-deep &.el-dialog {
    border-radius: 8px;
    .el-dialog__header {
      padding: 0;
    }
    .el-dialog__body {
      padding: 48px 70px 50px;
    }
  }
  .login_page {
    .login_logo {
      display: block;
      width: 80px;
    }
    .login_title {
      font-size: 18px;
      color: var(--blackColor);
      font-weight: bold;
      margin-top: 8px;
    }
    .longin_typeName {
      font-size: 16px;
      color: var(--blackColor);
      font-weight: bold;
      width: 100%;
      margin-top: 42px;
      margin-bottom: 13px;
    }
    .form_item {
      border-bottom: 1px solid #eef1f3;
      margin-bottom: 8px;
    }
    .getCode {
      width: 80px;
      padding-left: 5px;
      color: var(--blue);
      text-align: right;
      cursor: pointer;
      &.getingCode {
        color: #989ea7;
      }
    }
    .risk_list {
      width: 100%;
      padding-top: 9px;
      img {
        width: 16px;
        margin-right: 8px;
        cursor: pointer;
      }
      a {
        color: var(--blue);
        cursor: pointer;
      }
    }
    .submmit {
      width: 100%;
      margin-top: 30px;
      padding-top: 10px;
      padding-bottom: 10px;
      &.el-button--primary.is-disabled {
        background-color: #3975d6;
        color: rgba($color: #fff, $alpha: 0.5);
      }
      // height: 36px;
    }
    .loginType {
      width: 100%;
      margin-top: 5px;
      color: var(--blue);
      cursor: pointer;
    }
    .eyes {
      width: 20px;
      margin-left: 16px;
      cursor: pointer;
    }
  }
}
</style>
